<template>
  <svg
    class="icon-arrow-down"
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m12.37,7.33l-6,4.67L.37,7.33"
      stroke="currentColor"
      stroke-width="1.2"
    />

    <line
      x1="6.37"
      y1="12"
      x2="6.37"
      stroke="currentColor"
      stroke-width="1.2"
    />
  </svg>
</template>
